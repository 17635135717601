<template>
    <div>
        <v-tooltip bottom color="primary" :disabled="isFocus">
            <template v-slot:activator="{ on: onTooltip }">
                <v-card v-on="onTooltip"
                    color="transparent"
                    :disabled="$wait.is('searching community')"
                    flat
                    light
                    :width="!!(isFocus || model) ? 400 : getDefaultSearchBarWidth()"
                    class="transform ma-2 v-card-search"
                    :class="{'v-card-search--active': !!(isFocus || model)}"
                    >
                    <v-combobox
                        :items="[]"
                        v-model="searchTermModel"
                        :search-input.sync="model"
                        return-object
                        class="v-combobox--search v-combobox--outlined-variant elevation-1"
                        :class="{'rounded-circle': !isFocus && !model}"
                        hide-no-data
                        hide-details
                        :solo="!!(isFocus || model)"
                        :outlined="!isFocus && !model"
                        flat
                        clearable
                        full-width
                        dense
                        @focus="isFocus = true"
                        @blur="isFocus = false"
                    >
                        <template v-slot:label>
                            <div class="v-combobox--search-label">
                                <div v-if="$wait.is('searching community')" v-html="$t('community.search.loadingLabel')" />
                                <div v-else v-html="isFocus ? $t('community.search.defaultLabel') : ''" />
                            </div>
                        </template>
                        <template v-slot:prepend-inner>
                            <v-icon v-text="$wait.is('searching community') ? 'mdi-loading mdi-spin' : 'mdi-magnify'" :color="!!(isFocus || model) ? 'primary' : 'white'" :right="!isFocus && !model" />
                        </template>
                    </v-combobox>
                </v-card>
            </template>
            <span v-t="'community.search.defaultLabel'" />
        </v-tooltip>
         <v-sheet
            v-if="!$wait.is('searching community') && model && count !== null"
            color="white"
            >
            <v-sheet
                class="search-scroll-div white"
                :elevation="3"
                :height="'calc(100vh - 102px)'"
            >
                <template v-if="count === 0">
                    <div class="px-3 pb-3 pt-3">
                        {{ $t('community.noReportsFound') }}
                    </div>
                </template>
                <template v-else>
                    <div
                        v-for="category in searchResultItems"
                        :key="category.id"
                        class="px-3 pb-3 pt-3"
                    >
                        <router-link
                            :to="category.route"
                            class="text-decoration--none"
                            ><div class="text-subtitle-1 primary--text">
                                {{ category.title }}
                            </div></router-link
                        >
                        <template v-if="category.groups.length">
                            <div
                                v-for="group in category.groups"
                                :key="group.id"
                            >
                                <div class="text-body-2 mt-3">
                                    {{ group.title }}
                                </div>
                                <template v-if="group.items.length">
                                    <SearchResult
                                        v-for="item in group.items"
                                        :key="item.id"
                                        :item="item"
                                        :category="category"
                                        @clear-model="model = null"
                                    />
                                </template>
                            </div>
                        </template>
                    </div>
                </template>
            </v-sheet>
        </v-sheet>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { debounce, groupBy, chain } from 'lodash';
import SearchResult from './SearchResult.vue';
// import { parseISO, dateTypes, formatRelativeGroup } from '@/utils/dateFns';

const DEFAULT_SEARCHBAR_WITH = 40;

export default {
    props: {
        value: String,
    },
    components: {
        SearchResult,
    },
    i18n: {
        messages: {
            en: require('@/locales/Community/en.json'),
            de: require('@/locales/Community/de.json'),
        },
    },
    data() {
        return {
            isFocus: false,
            items: [],
            searchTermModel: null,
            count: null,
            headingLimit: 50,
        };
    },
    computed: {
        ...mapGetters({
            departmentCategoryItems: 'departmentCategories/getItems',
        }),
        model: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            },
        },
        searchResultItems() {
            return this.groupedCommunityItems.filter(x => x.groups.length);
        },
        groupedCommunityItems() {
            const items = this.items;
            const arrReturn = [];

            // const arrAuthorityNews = items.filter(x => x.category === 'authority_news');
            const arrTemplates = items.filter(x => x.category === 'template');
            const arrScenario = items.filter(x => x.category === 'scenario');
            const arrLeaNews = items.filter(x => x.category === 'lea_news');
            //const arrPenaltyFee = items.filter(x => x.category === 'penalty_fee');

            /*
            const groupedAuthorityNews = groupBy(arrAuthorityNews, item => {
                return (
                    item.date &&
                    formatRelativeGroup(
                        parseISO(item.date),
                        new Date(),
                        'week',
                        dateTypes.monthYear
                    )
                );
            });
            if (groupedAuthorityNews && groupedAuthorityNews.null) {
                delete groupedAuthorityNews.null;
            }
            arrReturn.push({
                id: 'authority_news',
                title: this.$i18n.t(
                    'community.categoryItems.authority_news.title'
                ),
                route: {
                    name: 'CommunityList',
                    params: { category: 'authority_news' },
                },
                groups: chain(groupedAuthorityNews)
                    .map((items, group) => {
                        if (group) {
                            return {
                                id: group,
                                title: group,
                                items: items,
                            };
                        }
                    })
                    .value(),
            });
            */

            const groupedTemplates = groupBy(arrTemplates, item => {
                return item.heading.substring(0, 1);
            });
            if (groupedTemplates && groupedTemplates.null) {
                delete groupedTemplates.null;
            }
            arrReturn.push({
                id: 'template',
                title: this.$i18n.t('community.categoryItems.template.title'),
                route: { name: 'CommunityList', params: { category: 'template' } },
                groups: chain(groupedTemplates)
                    .map((items, group) => {
                        if (group) {
                            return {
                                id: group,
                                title: group,
                                items: items,
                            };
                        }
                    })
                    .value(),
            });

            const groupedScenario = groupBy(arrScenario, item => {
                const department = this.departmentCategoryItems.find(
                    x => x.id === item.departmentCategory
                );
                return department && department.title ? department.title : '';
            });
            if (groupedScenario && groupedScenario.null) {
                delete groupedScenario.null;
            }
            arrReturn.push({
                id: 'scenario',
                title: this.$i18n.t('community.categoryItems.scenario.title'),
                route: { name: 'CommunityList', params: { category: 'scenario' } },
                groups: chain(groupedScenario)
                    .map((items, group) => {
                        if (group) {
                            return {
                                id: group,
                                title: group,
                                items: items,
                            };
                        }
                    })
                    .value(),
            });

            const groupedLeaNews = groupBy(arrLeaNews, item => {
                return item.heading.substring(0, 1);
            });
            if (groupedLeaNews && groupedLeaNews.null) {
                delete groupedLeaNews.null;
            }
            arrReturn.push({
                id: 'lea_news',
                title: this.$i18n.t('community.categoryItems.lea_news.title'),
                route: { name: 'CommunityList', params: { category: 'lea_news' } },
                groups: chain(groupedLeaNews)
                    .map((items, group) => {
                        if (group) {
                            return {
                                id: group,
                                title: group,
                                items: items,
                            };
                        }
                    })
                    .value(),
            });

            /*
            const groupedPenaltyFee = groupBy(arrPenaltyFee, item => {
                return (
                    item.date &&
                    formatRelativeGroup(
                        parseISO(item.date),
                        new Date(),
                        'week',
                        dateTypes.monthYear
                    )
                );
            });
            if (groupedPenaltyFee && groupedPenaltyFee.null) {
                delete groupedPenaltyFee.null;
            }
            arrReturn.push({
                id: 'penalty_fee',
                title: this.$i18n.t(
                    'community.categoryItems.penalty_fee.title'
                ),
                route: {
                    name: 'CommunityList',
                    params: { category: 'penalty_fee' },
                },
                groups: chain(groupedPenaltyFee)
                    .map((items, group) => {
                        if (group) {
                            return {
                                id: group,
                                title: group,
                                items: items,
                            };
                        }
                    })
                    .value(),
            });
            */

            return arrReturn;
        },
    },
    methods: {
        ...mapActions({
            fetchFilterItems: 'community/fetchFilterItems',
            fetchCountries: 'countries/fetch',
            fetchDepartmentCategories: 'departmentCategories/fetch',
        }),
        getDefaultSearchBarWidth () {
            return DEFAULT_SEARCHBAR_WITH;
        },
        async fetchData() {
            this.$wait.start('pageLoader');

            await Promise.all([
                this.fetchCountries(),
                this.fetchDepartmentCategories(),
            ]);
            this.$wait.end('pageLoader');
        },
        fetchSearchResult(val) {
            this.$wait.start('searching community');

            this.fetchFilterItems({
                page: 1,
                limit: 50,
                text: val || null,
                searchCategory: null,
                countryFilter: [],
                status: 'published',
                countries: null,
            }).then(data => {
                this.items = data.result;
                this.count = data.total;
                this.$wait.end('searching community');
            });
        },
    },
    created() {
        this.fetchData();
    },
    watch: {
        model: debounce(function(to, from) {
            if (to && to !== from && to.length > 3) {
                this.fetchSearchResult(to);
            }
        }, 500),
    },
};
</script>
